.bs-nft-block-wrapper {
  display: block;
  border: 1px solid #e7eaf3;
  border-radius: 0.5rem;
  padding: 0.75rem;
  background-color: var(--white);
  height: 100%;

  &:hover {
    box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.13);
  }

  .bs-nft-block-edn {
    font-size: 1rem;
    color: var(--white);
    background-color: rgba(119, 131, 143, .1);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

    .bs-nft-img-skeleton {
      height: 80%;
      width: 80%;
    }
  }

  .bs-nft-block {
    position: relative;
    height: 180px;
    max-width: 180px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    transition: all .2s;
    border-radius: 0.35rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.75rem;
    overflow: hidden;
  }
}
