.bs-transaction-additional-info {
  min-width: 240px;
  max-width: 300px;

  .bs-transaction-header {
    color: var(--font);
  }

  .bs-transaction-content {
    color: var(--font-secondary);

    span {
      color: var(--font);
    }
  }

  p,
  a,
  span,
  i,
  button {
    font-size: 12px;
  }

  hr {
    opacity: 0.1 !important;
    margin: 4px 0 !important;
  }
}

.token-type {
  @include background-opacity($orange);
  color: $orange;
  border: 1px solid $orange;
}

.bs-address-row {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-between;
  width: 75%;

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.bs-log-index {
  margin-left: auto;
}

.bs-log-index-box[data-active] {
  height: auto;
  background: #ebf8ff;
  border-color: #ebf8ff;
  color: #2b6cb0;
}

.bs-log-index-box {
  border-radius: 8px;
  height: 2rem;
  padding-inline-start: 0.75rem;
  padding-inline-end: 0.75rem;
  border-width: 2px;
  border-style: solid;
}

.bs-list-input-data {
  display: flex;
  column-gap: 1.25rem;
}

.bs-input-data-title {
  font-weight: 600;
}

.bs-log-table {
  background-color: var(--bs-body-bg);
  margin-top: 0.5rem;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 1rem;
}

.bs-log-margin {
  margin-right: 0.50rem;
}

.bs-log-arrow {
  width: auto;
  height: fit-content;
  position: relative;
}

.bs-log-dropdown {
  width: 100px;
  appearance: none;
  height: 32px;
  border-width: 2px;
  color: var(--font);
  outline: transparent solid 2px;
  background: var(--bs-bg);
}

.bs-log-dropdown-arrow {
  width: 1.5rem;
  right: 0.25rem;
  color: currentcolor;
  font-size: 1.25rem;
  position: absolute;
  display: inline-flex;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.bs-log-topic:not(:first-of-type) {
  margin-top: 0.75rem;
}

.bs-log-topic {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-inline: 0px;
  max-width: 100%;
}

.bs-list-log-data {
  border-radius: 12px;
  background-color: var(--bs-body-bg);
}

.bs-log-header {
  display: flex;
  justify-content: flex-start;
  column-gap: 1.25rem;
  font-weight: 600;
  padding-bottom: 0.25rem;
  row-gap: 1.25rem;
  line-height: 1.25rem;
}

.bs-log-footer {
  display: flex;
  justify-content: flex-start;
  column-gap: 1.25rem;
  padding-bottom: 0.25rem;
  row-gap: 1.25rem;
  line-height: 1.25rem;
}

.bs-log-table-cell {
  width: 80px;
  margin-right: 1.25rem;
}

.bs-log-tbl-row {
  margin-bottom: 1.25rem;
}

@media screen and (min-width: 1000px) {
  .bs-list-input-data {
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    flex-direction: row;
  }

  .bs-input-data-title {
    width: 80px;
  }

  .bs-log-topic {
    padding-inline-start: 0.75rem;
    padding-inline-end: 0.75rem;
  }
}

@media (max-width: 768px) {
  .bs-list-input-data {
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    flex-direction: column;
  }
}

.transaction-trace {
  color: var(--font);
  max-height: 400px;
  overflow: auto;
}

.bs-bottom-margin {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--border);
}

.gas-price-chart-stats {
  color: #4299e1;
}

.gas-price-chart-stats:hover {
  text-decoration: underline;
}

.raw-input-content {
  display: block !important;

  .bs-log-margin {
    display: inline;

    .bs-log-arrow {
      position: absolute;
    }
  }

  .bs-log-list-topic {
    margin-top: 15px;
    max-height: 150px;
    overflow: auto;
    padding: 5px 10px;
    background-color: var(--bs-bg);
    border: 1px solid var(--bs-border-color-translucent);
    border-radius: 10px;
  }
}

.custom-textarea-transaction {
  margin: -16px 16px -16px -16px;
  width: 103%;
}

.dropdown-item-hover-div {
  color: var(--font);
}

.dropdown-item-hover-div:hover {
  color: var(--font);
  background-color: var(--bs-border-color-translucent);
}

.transaction-input-data-button:hover {
  background-color: var(--bs-border-color-translucent);
}

.transaction-input-data-button.show {
  background-color: var(--bs-border-color-translucent);
  color: var(--bs-btn-active-color);
  border-color: var(--bs-btn-active-border-color);
}

.dropdown-menu-bg-color {
  background-color: var(--white);
  box-shadow: var(--shadow);
}

.active-dropdown-item {
  color: #0784c3;
  text-decoration: none;
  background-color: transparent;
}

.active-dropdown-item:hover {
  background-color: transparent;
  color: #0784c3;
}

.bs-row-div {
  border-bottom: 1px solid var(--font-secondary);
}

.scrollbox {
  overflow-y: auto;
  max-height: 370px;
  font-size: small;
  font-family: monospace;
  background-clip: padding-box;
}

.decoded-tab-item {
  overflow: auto;
  background-color: var(--bs-bg-secondary);
  border-radius: 10px;
}

.decoded-table {
  border: 1px solid var(--bs-border-color-translucent);
}

.decode-table-head {
  font-weight: 600;
}

.decode-tr-div {
  border: 1px solid var(--bs-border-color-translucent);
}

.decode-item-content {
  .bs-tab-item {
    font-size: small;
  }
}

@media (max-width: 768px) {
  .decode-item-content {
    width: calc(100% - 150px);
  }
}

.py-28 {
  padding: 7rem 0rem;
}

.text-yellow-600 {
  color: #cc9a06;
}

.page-error-title {
  font-size: 1.171875rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  color: var(--font);
}

.alert-dark {
  color: var(--font);
  background-color: var(--bs-border-color-translucent);
  border-color: var(--border);
}

.content-not-found {
  height: 4rem;
  width: 4rem;
}

.transaction-not-fount-ol {
  list-style: auto;
}
.flex {
  display: flex;
  align-items: center;
  gap: 0.5rem; // Adjust spacing between items
}

.gas-progress-bar {
  width: 3rem;
  max-width: 100%; 
  background-color: #e5e7eb;
  border-radius: 0.25rem;
  height: 0.2rem; 
  position: relative;

  .progress {
    background-color: #10b981;
    height: 100%; 
    border-radius: inherit;
    transition: width 0.3s ease; 
  }
}

.gas-progress-bar-label {
  color: #10b981; 
  font-size: 0.875rem;

}
