.bs-profile {
  .bs-banner {
    min-height: 164px;

    .container {
      padding: 32px 15px 80px;
    }
  }

  &-section {
    margin-top: -3rem !important;
  }
}