.token-unit-conversion {
  padding: 20px;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .icon {
      margin-right: 10px;
    }

    h1 {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .conversion-inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .error-message {
    color: red;
    font-size: 0.875rem;
  }
}
