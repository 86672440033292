// input-decoder.scss

.bs-page-title {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #1a1a1a;
  
    &-secondary {
      display: block;
      font-size: 0.875rem;
      color: #666;
      font-weight: normal;
      margin-top: 0.25rem;
    }
  }
  
  .bs-card {
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
  
    &-body {
      padding: 1.5rem;
    }
  }
  
  .decoded-results {
    &-header {
      margin-bottom: 1rem;
      font-size: 1.125rem;
      font-weight: 500;
  
      .result-count {
        color: #666;
        font-weight: normal;
      }
    }
  
    &-warning {
      background-color: #f8f9fa;
      border-radius: 0.375rem;
      padding: 1rem;
      margin-bottom: 1.5rem;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      color: #666;
  
      i {
        font-size: 1.25rem;
      }
    }
  
    &-content {
      .function-section {
        margin-bottom: 1.5rem;
  
        .function-label {
          color: #666;
          margin-right: 0.5rem;
        }
  
        .function-badge {
          background-color: #f8f9fa;
          padding: 0.25rem 0.75rem;
          border-radius: 0.25rem;
          font-size: 0.875rem;
          font-family: monospace;
        }
      }
  
      .parameter-section {
        margin-bottom: 1.5rem;
  
        .param-header {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin-bottom: 0.5rem;
  
          .param-type {
            color: #666;
          }
  
          .param-name {
            background-color: #f8f9fa;
            padding: 0.25rem 0.75rem;
            border-radius: 0.25rem;
            font-size: 0.875rem;
            font-family: monospace;
          }
        }
  
        .param-value {
          background-color: #f8f9fa;
          border-radius: 0.375rem;
          padding: 0.75rem;
          position: relative;
          font-family: monospace;
          word-break: break-all;
  
          .copy-btn {
            position: absolute;
            right: 0.75rem;
            top: 50%;
            transform: translateY(-50%);
            background: none;
            border: none;
            color: #666;
            cursor: pointer;
            padding: 0.25rem;
  
            &:hover {
              color: #333;
            }
  
            i {
              font-size: 1rem;
            }
          }
        }
      }
    }
  
    .copy-all-btn {
      background-color: #0d6efd;
      color: white;
      border: none;
      border-radius: 0.375rem;
      padding: 0.5rem 1rem;
      cursor: pointer;
      font-weight: 500;
  
      &:hover {
        background-color: #0b5ed7;
      }
    }
  }
  
  // Form styles
  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  
    &:focus {
      color: #212529;
      background-color: #fff;
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
  }
  
  .form-label {
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #212529;
  }
  
  // Alert styles
  .alert {
    position: relative;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.375rem;
  
    &-warning {
      color: #664d03;
      background-color: #fff3cd;
      border-color: #ffecb5;
    }
  
    &-success {
      color: #0f5132;
      background-color: #d1e7dd;
      border-color: #badbcc;
    }
  }
  
  // Button styles
  .btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.375rem;
    transition: color 0.15s ease-in-out, 
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
  
    &-light {
      color: #000;
      background-color: #f8f9fa;
      border-color: #f8f9fa;
  
      &:hover {
        color: #000;
        background-color: #f9fafb;
        border-color: #f9fafb;
      }
    }
  }