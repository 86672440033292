.bs-verify-contract {
  .bs-field {
    margin-bottom: 20px;
  }

  .form-check {
    min-height: 40px;
  }

  .bs-optimization {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .bs-checkbox {
      margin-right: 12px;
    }

    .form-control {
      width: 120px;
    }
  }

  .bs-files {
    margin-bottom: 20px;

    &-list {

      border: 1px dashed var(--border);
      border-radius: 8px;
      padding: 12px;
      background-color: var(--bs-bg-secondary);
      margin-bottom: 12px;
    }
  }
}

.bs-dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: var(--bs-body-bg);
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;

  h4 {
    color: var(--font);
  }
}
.margin {
  margin-left: 2px;
}