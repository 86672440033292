
.bs-empty {
  min-height: 300px;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;

  &-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    svg {
      margin: 8px;
    }

    h4 {

    }
  }
}
