.code-reader-content {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: 16px 0px 0px !important;
}

@media (max-width: 768px) {
  .code-reader-content {
    overflow: auto;
    overflow: visible;
    padding: 0 20px;
  }

  .right-part {
    margin-top: 10px;
  }

  .code-reader-content .keySelect {
    width: 100% !important;
  }
  .code-reader-content-scroll {
    overflow-y: scroll;
  }
}

@media (max-width: 768px) {
  .code-container {
    width: calc(100% - 10vh) !important;
  }
}

@media (max-width: 567px) {
  .code-container {
    width: 55vh !important;
  }
}

@media (max-width: 480px) {
  .code-container {
    width: 45vh !important;
  }
}

.keyUl {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .keyList {
    height: auto;
    padding: 15px 0px 12px 0px;
  }
}

.keyList {
  cursor: pointer;
  flex: 1 1;
  margin: 10px 0px 20px 0;
  padding: 16px 12px;
  position: relative;
}

.keyList_active {
  border: 1px solid var(--font);
}

.title {
  color: #404245;
}

.keyList .title {
  color: #91979d;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 16px;
  text-align: center;
}

.coderun-ai-contract {
  background: var(--bs-bg) !important;

  .ai-contract-select__single-value {
    color: var(--font) !important;
  }
}

.ai-contract-select__menu {
  border: 1px solid var(--bs-border-color-translucent);
  background: var(--bs-bg) !important;
  color: var(--font) !important;
}

.code-reader-content .keySelect {
  flex-shrink: 0;
  width: 408px;
  padding: 0px 20px;
}

.code-reader-form-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.code-reader-button-bg:hover {
  background-color: #c23631 !important;
}

.code-reader-input-status-success {
  background: #fafbfb !important;
  box-shadow: none;
  color: #404245;
  font-size: 10px;
  line-height: 20px;
  margin-bottom: 24px;
  outline: none;
  padding: 16px 16px 0;
}

.code-reader-trending-tab {
  font-size: 12px;
}

.Code-Reader-Tab {
  background: #fafbfb;
  border: 1px solid rgba(194, 201, 209, 0.2);
  border-radius: 6px;
  cursor: pointer;
  margin: 0px 6px 10px 6px;
  padding: 4px 16px;
  position: relative;
  min-width: 74px;
  width: fit-content;
}

.Code-Reader-Tab-active,
.Code-Reader-Tab:hover {
  background: var(--white) !important;
  border: 1px solid #404245 !important;
}

.code-reader-contract-file-block {
  background: #fafbfb;
  border: 1px solid rgba(194, 201, 209, 0.2);
  border-radius: 6px;
  overflow-y: auto;
  padding: 12px 16px;
  font-size: 12px;
  max-height: 200px;
}

.fileContractSelectAutoHeight {
  height: auto;
}

.code-reader-source-code-no-drop {
  cursor: no-drop !important;
}

.user-dialogue {
  width: 80%;
}

.try-wrapper {
  background-color: #e74c3c;
  border-radius: 7px 7px 7px 0;
  color: var(--white);
  font-size: 10px;
  font-weight: 500;
  height: 16px;
  line-height: 14px;
  padding: 3px 4px;
  position: absolute;
  top: 20px;
}

.code-reader-tag {
  border-radius: 7px 7px 7px 0;
  color: var(--white);
  font-size: 10px;
  font-weight: 500;
  height: 16px;
  line-height: 14px;
  padding: 3px 4px;
  position: absolute;
}

.code-reader-new-tag {
  background-color: #e74c3c;
  top: 5px;
  cursor: default;
  left: 120px;
  position: absolute;
}

.code-reader-demo-tag {
  background-color: #2d912c;
  top: 4px;
  right: 5px;
}

.code-reader-content-scrollbar::-webkit-scrollbar {
  width: 2px;
}

.code-reader-content-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.code-reader-content-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

.code-reader-content-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.code-reader-content-scroll {
  max-height: 80vh;
  padding-bottom: 20px;
}

.code-reader-content-scroll-keyselect {
  max-height: 76vh;
  overflow-y: scroll;
}

.code-reader-content-scroll-right-part {
  max-height: 76vh;
}

.code-reader-header-tag {
  position: relative;
}

.right-part-wrapper {
  padding: 16px 20px;
}

.right-part-wrapper {
  flex: 1 1;
  height: 100%;
  width: calc(100% - 388px);
}

.chat-wapper {
  background: var(--bs-bg);
  border: 1px solid var(--border);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 20px;
  position: relative;
}

.chat-wapper .chat-content-outerlayer-wrapper {
  flex: 1 1;
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: 50px;
  position: relative;
}

.chat-wapper .chat-content-wrapper {
  height: 94%;
  padding: 0 20px 20px;
}

.chat-wapper .chat-content-wrapper .other-dialogue-item {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
}

.avatar {
  height: 40px;
  margin-right: 14px;
}

.flex-start {
  align-items: center;
  display: flex;
}

.tron-icon {
  fill: currentColor;
  height: 1em;
  overflow: hidden;
  vertical-align: -0.15em;
  width: 1em;
}

.chat-wapper .chat-content-wrapper .other-dialogue-item .dialogue-wrapper {
  width: 75%;
}

.chat-greet-item .dialogue-wrapper {
  padding-top: 0 !important;
}

.chat-wapper
.chat-content-wrapper
.other-dialogue-item
.dialogue-wrapper
.dialogue {
  background: var(--white);
  border-radius: 0 20px 20px 20px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  overflow-x: auto;
}

.chat-greet-item .dialogue-wrapper .dialogue {
  margin-top: 0 !important;
  overflow-x: hidden !important;
}

.chat-wapper .chat-content-wrapper .dialogue {
  color: #404245;
  font-size: 14px;
  line-height: 20px;
  padding: 15px;
  white-space: normal;
}

.chat-greet-item .dialogue-wrapper .hi-there {
  color: #c2c9d1;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  margin-right: 8px;
}

.chat-greet-item .dialogue-wrapper .greet {
  animation: greetAnimate 0.8s linear infinite;
  display: inline-block;
  font-size: 20px;
  transform-origin: bottom right;
}

.chat-wapper
.chat-content-wrapper
.other-dialogue-item
.dialogue-wrapper
.dialogue
> div {
  margin-right: 6px;
}

.message-container {
  width: 100%;
}

.code-reader-help-title {
  font-size: 20px;
  font-weight: 600;
}

.ai-title {
  display: block;
  text-align: center;
}

.code-reader-contarct-functionality {
  margin: 20px;
}

.code-reader-contract-function-tab {
  background: var(--bs-bg);
  border: 1px solid var(--bs-border-color-translucent);
  border-radius: 13px;
  color: var(--font-secondary);
  font-size: 11px;
  overflow: auto;
  padding: 8px;
  text-align: center;
  cursor: pointer;
}

.code-reader-contract-function-tab:hover {
  color: var(--font);
  border: 1px solid var(--black);
  transform: scale(1.01);
}

.chat-wapper .chat-input-wrapper {
  flex-shrink: 0;
  height: 50px;
  position: sticky;
  z-index: 1;
  bottom: 0px;
}

.chat-wapper .chat-input-wrapper .textarea-wrapper {
  background: var(--bs-bg);
  border: 1px solid var(--bs-border-color-translucent);
  border-radius: 30px;
  bottom: 0;
  left: 0;
  margin-left: 20px;
  overflow: hidden;
  position: absolute;
  width: calc(100% - 40px);
}

.chat-wapper .chat-input-wrapper .textarea-wrapper .can-scroll {
  line-height: 1;
  max-height: 140px;
  overflow-y: auto;
  padding-right: 58px;
}

.chat-wapper .chat-input-wrapper .textarea-wrapper textarea {
  background: var(--bs-bg);
  border: var(--bs-border-color-translucent);
  box-shadow: none;
  color: var(--font);
  line-height: 20px;
  min-height: auto;
  outline: none;
  padding: 14px 0 14px 16px;
}

.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}

textarea.ant-input {
  height: auto;
  min-height: 32px;
  line-height: 1.5714285714285714;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
  resize: vertical;
  width: 100%;
}

.ant-input {
  color: rgba(0, 0, 0, 0.65);
}

.ant-card,
.ant-input {
  line-height: 1.5;
}

.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: var(--white);
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}

.chat-wapper .chat-input-wrapper .textarea-wrapper .submit-btn {
  background-color: var(--font);
  border-radius: 20px;
  bottom: 7px;
  color: var(--white);
  cursor: pointer;
  font-size: 32px;
  height: 34px;
  line-height: 34px;
  position: absolute;
  right: 8px;
  text-align: center;
  width: 34px;
}

.question-no-drop-icon {
  cursor: no-drop !important;
  opacity: 0.5;
}

.chat-content-area-div {
  height: 100%;
  margin-top: 57px;
  overflow-y: auto;
  padding: 0 20px 20px;
  height: 52vh;
}

.contract-code-reader {
  width: inherit;
  font-size: 10px;
}

.ant-list {
  padding-bottom: 20px;
}

.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
}

.ant-spin-nested-loading {
  position: relative;
}

.ant-spin-nested-loading .ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}

.ant-list .ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-list-items .ant-list-item {
  border-block-end: none;
  display: block;
  padding: 0;
}

.me-dialogue-item {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.ant-list * {
  outline: none;
}

.chat-wapper .chat-content-wrapper .me-dialogue-item .dialogue {
  background: var(--bs-border-color-translucent);
  border-radius: 20px 0 20px 20px;
  color: var(--font);
  font-weight: 500;
  margin-left: 20%;
}

.chat-wapper .chat-content-wrapper .me-dialogue-item .avatar {
  background-color: #2d912c;
  line-height: 40px;
  margin-left: 14px;
  text-align: center;
}

.chat-wapper .chat-content-wrapper .avatar {
  border-radius: 50%;
  color: var(--white);
  flex-shrink: 0;
  font-size: 14.4px;
  font-weight: 500;
  height: 40px;
  width: 40px;
}

.chat-set-reminder-item {
  margin-bottom: 10px;
}

.chat-set-reminder-item .dialogue-wrapper {
  padding-top: 0 !important;
}

.chat-set-reminder-item .dialogue-wrapper .dialogue {
  background: var(--bs-border-color-translucent) !important;
  color: var(--font);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-top: 0 !important;
  padding: 14px !important;
}

.chat-set-reminder-item .dialogue-wrapper .dialogue .finger {
  animation: fingerAnimate 1s infinite;
  display: inline-block;
  font-size: 20px;
  margin-right: 8px;
}

.chat-set-reminder-item .dialogue-wrapper .dialogue .need-set-input-item {
  margin: 0 4px;
}

.chat-set-reminder-item .dialogue-wrapper .dialogue .re-submit {
  cursor: pointer;
  margin: 0 4px;
  text-decoration: underline;
}

.ant-spin-nested-loading .ant-spin-container::after {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: var(--white);
  opacity: 0;
  transition: all 0.3s;
  content: "";
  pointer-events: none;
}

.chat-example-wrapper {
  align-items: flex-start;
  bottom: 68px;
  flex-shrink: 0;
  font-size: 12px;
  left: 0px;
  cursor: pointer;
  line-height: 16px;
  margin-right: 3px;
  margin-top: 15px;
  padding: 0px 20px 10px;
  position: absolute;
  width: calc(100% - 3px);

  .code-question-tab {
    height: 36px;
    overflow: hidden;
  }

  .question-tab {
    border-radius: 12px;
  }

  .question-tab:hover {
    color: var(--white);
    background-color: $primary;
  }
}

.show-more {
  color: #4299e1;
  font-size: small;
}

.bs-bottom-margin-code {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--border);
}

// waiting loader css
.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}

.lds-ellipsis {
  bottom: 18px;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: $primary;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
