.bs-rewards {
  &-amount {
    display: flex;
    align-items: center;

    &-inner {
      flex-grow: 1;
      margin-right: 10px;

      .form-control {
        padding-right: 60px;
      }

      .bs-input-content {
        position: absolute;
        right: 10px;
        color: $font;
        font-size: 16px;
      }
    }
  }


  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    h2 {
      font-size: 20px;
    }

    span {
      font-size: 20px !important;
    }
  }

  &-total {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--border);
    padding: 12px;

    &:last-child {
      border-bottom: none;
    }

    h2 {
      font-size: 16px;
      text-transform: uppercase;
      color: var(--font-secondary)
    }
  }

  &-number {
    p {
      text-align: right;
      font-size: 20px;
      font-weight: bold;
    }

    span {
      display: block;
      text-align: right;
      font-size: 16px;
      color: var(--font-secondary)
    }
  }
}
