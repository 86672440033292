.project-card {
  position: relative;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background: white;
  border-radius: 15px;
  overflow: hidden;
  height: 100%;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      225deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );
    transform: translateX(-100%);
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: translateX(-100%);
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:hover {
    transform: translateY(-15px) rotate(2deg);
    box-shadow: 
      rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px,
      0 0 20px rgba(192, 219, 255, 0.6),
      0 0 40px rgba(65, 120, 255, 0.2);
    
    &::before {
      transform: translateX(100%);
    }
    
    &::after {
      transform: translateX(100%);
    }
    
    .card-content {
      .project-icon {
        transform: rotate(-10deg) scale(1.1);
      }
      
      .project-title {
        transform: scale(1.05);
        background: linear-gradient(45deg, #2196F3, #E91E63);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      
      .project-badge {
        transform: translateX(5px);
        background: linear-gradient(45deg, #2196F3, #E91E63);
        color: white;
      }
    }
  }

  .card-content {
    position: relative;
    z-index: 1;
    
    .project-icon {
      transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    
    .project-title {
      transition: all 0.3s ease;
    }
    
    .project-badge {
      transition: all 0.3s ease;
    }
    
    .project-description {
      position: relative;
      z-index: 1;
      
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, transparent, #2196F3, transparent);
        transform: scaleX(0);
        transition: transform 0.3s ease;
      }
    }
  }

  &:hover .project-description::after {
    transform: scaleX(1);
  }
}

// Add some cool animation keyframes
@keyframes sparkle {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}