.bs-badge {
  padding: 4px 6px;
  font-weight: 500;
  border-radius: 6px;
  font-size: 12px;

  * {
    font-size: 12px;
  }

  &.h-small {
    font-size: 10px;
    padding: 0 4px;

    * {
      font-size: 10px;
    }
  }

  &.h-gray {
    background-color: var(--bs-bg);
    border: 1px solid var(--bs-border-color-translucent);
    color: var(--font);
    padding: 2px 8px;
  }

  &.p-gray {
    color: #77838f !important;
    background-color: rgba(119, 131, 143, 0.1) !important;
  }

  &.bg-gray {
    background-color: rgba(119, 131, 143, 0.1) !important;
  }

  &.contain-text {
    background-color: var(--bs-bg);
    border: 1px solid var(--bs-border-color-translucent);
    color: var(--font);
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }

  &.h-green {
    @include background-opacity($green);
    border: 1px solid $green;
    color: $green;
  }

  &.h-red {
    @include background-opacity($red);
    border: 1px solid $red;
    color: $red;
  }

  &.h-yellow {
    @include background-opacity($yellow);
    border: 1px solid $yellow;
    color: $yellow;
  }
}

.form-check-input {
  margin-left: 2px !important;
  margin-right: 0.25rem !important;
}

.score-chart-container {
  padding: 1.5rem 0;

  .chart-wrapper {
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  .score-heading {
    font-size: 1.75rem;
    color: var(--primary);
    transition: color 0.3s ease;

    &:hover {
      color: var(--primary);
    }
  }

  .score-number {
    font-size: 1rem;
    font-weight: bold;
  }

  .score-description {
    font-size: 1rem;
    color: var(--bs-dashboard-title);
    line-height: 1.5;
  }

  @media (max-width: 768px) {
    .score-heading {
      font-size: 1.5rem;
    }

    .score-number {
      font-size: 1.75rem;
    }

    .score-description {
      font-size: 0.9rem;
    }
  }
}
