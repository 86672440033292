.nav-pills {
  .nav-link {
    padding: 0.3rem 0.6rem;
    font-size: 12px;
    color: var(--font);
    font-weight: 500;
    border-radius: 0.5rem;

    &.active {
      background-color: $primary;
    }
  }


  .nav-link:hover:not(.active) {
    background-color: var(--bs-border-color-translucent);
  }

  .chart-menu {
    color: var(--font);
    font-size: 0.8rem;
  }
}

.cm-editor {
  background-color: var(--bs-bg-secondary);
  font-size: 80%;
}

.container {
  max-width: 1400px !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
  @media (max-width: 767px) {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
}

a {
  &:focus-visible, &:focus {
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
  }
}

.btn {
  font-size: 14px !important;

  &-primary {
    border: none !important;
    background-color: $primary !important;
  }
}

.form-check-input {
  &:checked {
    background-color: $primary !important;
    border-color: $primary !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.progress-bar {
  background-color: $primary;
}

.slick-slide img {
  z-index: 1;
  position: relative;
}

button.link-dark,
input[type="button"].link-dark,
input[type="submit"].link-dark {
  background-color: $bs-gray;
  color: $font;

  &:hover {
    background-color: $bg;
    border: 1px solid $border;
  }
}

.input-range__track--active {
  background-color: $primary !important;
}

.input-range__slider {
  background-color: $primary !important;
}

.gray {
  color: var(--font-secondary);
}

.red {
  color: $red;
}

.green {
  color: $green;
}