.bs-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--white);
  background-clip: border-box;
  border: 1px solid var(--border);
  border-radius: 12px;
  box-shadow: var(--shadow);
  color: var(--font);

  &-header {
    padding: 16px;
    margin-bottom: 0;
    border-bottom: 1px solid var(--border);

    &-title {
      color: var(--font);
      font-size: 0.875rem;
      font-weight: 600;
      margin-bottom: 0;
      flex: 1 auto;
    }
  }

  &-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 16px;
    color: var(--font);
  }

  &-footer {
    padding: 16px;
    border-top: 1px solid var(--border);
    background-color: var(--bs-bg-secondary);
  }
}

.bs-card-footer a {
  color: var(--font-secondary);
  font-size: 0.75rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 450 !important;

  &:hover {
    color: #066a9c;
  }
}

.bs-card-table-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    display: block;
  }
}