.bs-skeleton {
  overflow: hidden;
  width: 100%;
  margin: 4px;

  &-round {
    border-radius: 50%;
  }

  &-input {
    border-radius: 5px;
    border: 1px solid var(--border);
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;

    .bs-loading {
      height: 24px;
      width: 70%;
      border-radius: 5px;
    }
  }
}

.bs-loading {
  position: relative;
  background-color: var(--border);
  height: 100%;
}

.bs-loading::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba(255, 255, 255, 0.2)), to(transparent));
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
  animation: bs-loading 1s infinite;
}

@keyframes bs-loading {
  100% {
    transform: translateX(100%);
  }
}

.skeleton-card {
  padding: 15px;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.skeleton-left, .skeleton-right {
  margin-bottom: 15px;
}

.skeleton-left {
  display: flex;
  justify-content: center;
}

.skeleton-right {
  display: flex;
  flex-direction: column;
}

.square {
  height: 80px;
  border-radius: 5px;
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
  background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-squares 2s infinite ease-out;
}

.line {
  height: 12px;
  margin-bottom: 6px;
  border-radius: 2px;
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
  background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.circle {
  border-radius: 50% !important;
  height: 80px !important;
  width: 80px;
}

@keyframes wave-lines {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes wave-squares {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
