.bs-input {
}

.input-group-text {
  background-color: var(--bs-body-bg);
  color: var(--font);
}

.font-b {
  font-weight: 400 !important;
}

.bg-meth {
  background-color: rgb(248, 249, 250) !important;
  text-transform: capitalize;
  color: rgb(0, 0, 0) !important;
}

.py-1\.5 {
  padding-top: .375rem !important;
  padding-bottom: .375rem !important;
}

.linkb1 {
  color: $theme;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}