.bs-footer {
  color: var(--font);
  border-top: 1px solid var(--border);
  background: var(--bs-bg-secondary);
  border-color: transparent;
  margin-top: auto;

  .social-icon {
    color: $theme !important;
    background-color: $gray !important;
    border-color: $gray !important;
    border: none !important;
    padding: 6px;
  }
}

.custom-fox {
  border-radius: .25rem;
  color: var(--font);
  background-color: var(--bs-bg-secondary);
  border-color: var(--border);

  &:hover {
    background-color: $hover;
    border-color: $hover;
  }
}