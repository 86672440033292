.bs-address-token {
  border: 1px solid var(--border);
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
  background-color: var(--hover);
  width: 100%;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;

  &-content {
    min-width: 400px;
    width: 100%;
    height: 400px;
    padding: 0 !important;
  }

  &-header {
    padding: 12px;
    border-bottom: 1px solid var(--border);

    input {
      width: 100%;
      font-size: 12px;
    }
  }

  &-body {
    padding: 12px;
    overflow: auto;
    height: 343px;

    ul {
      li {
        a {
          cursor: pointer;
          padding: 8px;
          border-bottom: 1px solid var(--border);
          display: flex;
          justify-content: space-between;
          font-size: 12px;

          &:hover {
            background-color: var(--hover);
          }
        }
      }
    }
  }

  &-footer {
    padding: 12px;
    border-top: 1px solid var(--border);
    text-align: center;
    cursor: pointer;
  }
}

.bg-address-multichain-tab:hover {
  color: var(--font);
  background-color: var(--bs-border-color-translucent);
  border: 1px solid var(--bs-gray-500);
}

.bg-address-multichain-toggle {
  background-color: transparent !important;
}

.bg-address-multichain-toggle:hover {
  color: var(--bs-primary) !important;
}

.bs-multichain-portfolio-custom-btable .bs-card {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.selected-bg-color {
  color: var(--font);
  background-color: var(--bs-border-color-translucent);
  border: 1px solid var(--bs-gray-500);
}

.bg-address-multichain-tab.selected-bg-color {
  opacity: 1 !important;
}

.bg-address-multichain-tab:not(.selected-bg-color) {
  opacity: 0.5 !important;
}
