.bs-table {
  width: 100%;
  display: table;
  margin: 0;

  &-row {
    display: table-row;
    width: 100%;

    &:hover {
      background-color: var(--hover);
    }
  }

  &-header {
    &:hover {
      background-color: transparent !important;
    }

    .bs-table-cell {
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      padding: 10px 8px;
    }
  }

  &-cell {
    display: table-cell;
    font-size: 14px;
    line-height: 28px;
    padding: 10px 8px;
    border-bottom: 1px solid var(--border);
    vertical-align: middle;

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }
  }
}

.bs-custom-table {
  width: 100%;
  display: table;
  margin: 0;
  border: 1px solid var(--border);
  border-radius: 18px !important;

  &-row {
    display: table-row;
    width: 100%;

    &:hover {
      background-color: var(--hover);
    }
  }

  &-header {
    background-color: var(--bs-header-hover-bg);
    // &:hover {
    //   background-color: transparent !important;
    // }

    .bs-custom-table-cell {
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      padding: 14px 8px;
    }
  }

  &-cell {
    display: table-cell;
    font-size: 14px;
    line-height: 28px;
    padding: 10px 8px;
    border-bottom: 1px solid var(--border);
    vertical-align: middle;

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }
  }
}
