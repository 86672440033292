$themes: (
        black: (
                darkTheme: $white,
                dimTheme: $white,
                lightTheme: $black,
        ),
        white: (
                darkTheme: $black,
                dimTheme: $bg-for-dim,
                lightTheme: $white,
        ),
        border: (
                darkTheme: $border-for-dark,
                dimTheme: $border-for-dim,
                lightTheme: $border,
        ),
        shadow: (
                darkTheme: $shadow-for-dark,
                dimTheme: $shadow-for-dim,
                lightTheme: $shadow,
        ),
        bs-bg: (
                darkTheme: $bg-for-dark,
                lightTheme: $bg,
                dimTheme: $bg-for-dim,
        ),
        bs-bg-secondary: (
                darkTheme: $bg-secondary-for-dark,
                dimTheme: $bg-secondary-for-dim,
                lightTheme: $bg-secondary,
        ),
        bs-body-bg: (
                darkTheme: $bg-secondary-for-dark,
                dimTheme: $bg-secondary-for-dim,
                lightTheme: $bg-secondary,
        ),
        hover: (
                darkTheme: $hover-for-dark,
                dimTheme: $hover-for-dark,
                lightTheme: $hover,
        ),
        bs-dark-rgb: (
                darkTheme: $font-for-dark,
                dimTheme: $font-for-dark,
                lightTheme: $font,
        ),
        bs-light-rgb: (
                darkTheme: $font-for-dark,
                dimTheme: $font-for-dark,
                lightTheme: $font,
        ),
        bs-black-rgb: (
                darkTheme: $font-for-dark,
                dimTheme: $font-for-dark,
                lightTheme: $font,
        ),
        bs-gray-750: (
                darkTheme: $bs-gray-for-dark,
                dimTheme: $bs-gray-for-dim,
                lightTheme: $bs-gray,
        ),
        font: (
                darkTheme: $font-for-dark,
                dimTheme: $font-for-dark,
                lightTheme: $font,
        ),
        font-secondary: (
                darkTheme: $font-secondary-for-dark,
                dimTheme: $font-secondary-for-dark,
                lightTheme: $font-secondary,
        ),
        bs-secondary-color: (
                darkTheme: $bs-secondary-color-for-dark,
                dimTheme: $bs-secondary-color-for-dark,
                lightTheme: $bs-secondary-color,
        ),
        bs-border-color-translucent: (
                darkTheme: $bs-light-gray-for-dark,
                dimTheme: $bs-light-gray-for-dim,
                lightTheme: $gray,
        ),
        bs-navbar-toggler-border-color: (
                darkTheme: $bs-light-gray-for-dark,
                dimTheme: $bs-light-gray-for-dark,
                lightTheme: $gray,
        ),
        filter-toggle: (
                darkTheme: 1,
                dimTheme: 1,
                lightTheme: 0,
        ),
        bs-btn-bg: (
                darkTheme: $bs-btn-bg-for-dark,
                dimTheme: $bs-btn-bg-for-dim,
                lightTheme: $bs-btn-bg,
        ),
        bs-btn-hover-bg: (
                darkTheme: $bs-btn-hover-bg-for-dark,
                dimTheme: $bs-btn-hover-bg-for-dim,
                lightTheme: $bs-btn-hover-bg,
        ),
        bs-header-hover-bg: (
                darkTheme: $bs-header-hover-bg-for-dark,
                dimTheme: $bs-header-hover-bg-for-dim,
                lightTheme: $bs-header-hover-bg,
        ),
        bs-header-hover-text:(
                darkTheme: $bs-header-hover-text-for-dark,
                dimTheme: $bs-header-hover-text-for-dark,
                lightTheme: $bs-header-hover-text,
        ),
        bs-dashboard-text:(
                darkTheme: $bs-dashboard-text-for-dark,
                dimTheme: $bs-dashboard-text-for-dark,
                lightTheme: $bs-dashboard-text,
        ),
        bs-dashboard-title:(
                darkTheme: $bs-dashboard-title-for-dark,
                dimTheme: $bs-dashboard-title-for-dark,
                lightTheme: $bs-dashboard-title,
        ),
        bs-bg-theme:(
                darkTheme: $bs-bg-card-for-dark,
                dimTheme: $bs-bg-card-for-dim,
                lightTheme: $bs-bg-card,
        ),
        bs-text-chart:(
                darkTheme: $bs-text-chart-for-dark,
                dimTheme: $bs-text-chart-for-dim,
                lightTheme: $bs-text-chart,
        ),
        bs-text-card-title:(
                darkTheme: $bs-text-card-title-for-dark,
                dimTheme: $bs-text-card-title-for-dim,
                lightTheme: $bs-text-card-title,
        )
);

@mixin styles($mode) {
  @each $key, $map in $themes {
    @each $prop, $color in $map {
      @if $prop == $mode {
        --#{$key}: #{$color};
      }
    }
  }
}

.dark-mode {
  @include styles("darkTheme");
}

.light-mode {
  @include styles("lightTheme");
}

.dim-mode {
  @include styles("dimTheme");
}
