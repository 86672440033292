html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

body {
  font-family: "Fira Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--font);
  text-align: left;
  overflow-x: hidden;
}

a {
  color: $primary;
  background-color: transparent;
  text-decoration: none;
}

.bs-font {
  color: var(--font);
}

.bs-font-secondary {
  color: var(--font-secondary);
}

.bs-font-primary {
  color: $primary;
}

.bs-font-theme {
  color: $theme;
}

.bs-small-font {
  font-size: 12px;
}

.bs-layout {
  background-color: var(--bs-bg);
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.bs-body {
  background-color: var(--bs-bg);
  min-height: 400px;
  flex: 1;
}

.bs-page-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--border);
  display: flex;
  align-items: center;

  &-secondary {
    margin-left: 12px;
    font-size: 14px;
    font-weight: normal;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .date {
      font-size: 14px;
      font-weight: normal;
      margin-top: 5px;
      margin-left: 0;
    }
  }
}
hr {
  border-color: var(--border);
  opacity: 1 !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.bs-cp {
  cursor: pointer;
}

hr {
  opacity: 0.5 !important;
}

.b-fs-12 {
  font-size: 12px !important;
}

.bs-ad-banner {
  a {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 8px;
      width: 100%;
    }
  }
}

.page-title {
  font-size: 18px;
  font-weight: bold;
}

.table-border-row {
  border-bottom: 1px solid #dedede;
}

.bs-copy {
  cursor: pointer;
  color: var(--font-secondary);
  font-size: 12px;

  &:hover {
    color: $primary;
  }
}

.bs-copied {
  color: $success;
  font-size: 14px;
}

.bs-qr-code {
  min-width: 200px !important;

  .bs-card-header {
    &-title {
      display: flex;
      justify-content: space-between;

      i {
        cursor: pointer;
      }
    }
  }

  &-box {
    text-align: center;

    p {
      padding: 12px 0 0 0;
    }
  }
}

.bs-coming-soon {
  padding: 48px;

  &-container {
    text-align: center;
  }

  img {
    max-width: 400px;
  }

  h2 {
    font-size: 36px;
    color: $primary;
  }
}

.bs-dot {
  margin: 5px;
  height: 5px;
  width: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  background: var(--font-secondary);
  box-shadow: 0 0 15px rgba(108, 117, 125, 40);
  border: 1px solid var(--font-secondary) !important;
  position: relative;

  &.h_red {
    background: $red;
    box-shadow: 0 0 15px rgba(220, 53, 69, 40);
    border: 1px solid $red !important;

    &:before,
    &:after {
      background: $red;
    }
  }

  &.h_green {
    background: $green;
    box-shadow: 0 0 15px rgba(25, 135, 84, 40);
    border: 1px solid $green !important;

    &:before,
    &:after {
      background: $green;
    }
  }

  &:before,
  &:after {
    content: "";
    width: 9px;
    height: 9px;
    border-radius: 50%;
    z-index: -1;
    position: absolute;
    animation: inbox 2s linear infinite;
    background: var(--font-secondary);
  }

  &:before {
    animation-delay: 0s;
  }

  &:after {
    animation-delay: 1.5s;
  }
}

@keyframes inbox {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1.7, 1.7);
    opacity: 0;
  }
}

.bs-gauge {
  position: relative;
  border-radius: 50%/100% 100% 0 0;
  background-color: var(--color, #a22);
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    padding-top: 50%;
  }

  &:hover {
    --rotation: 100deg;
  }

  .h_mask {
    position: absolute;
    left: 20%;
    right: 20%;
    bottom: 0;
    top: 40%;
    background-color: var(--white);
    border-radius: 50%/100% 100% 0 0;
  }

  .h_percentage {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: 0;
    right: -1px;
    background-color: var(--background, #aaa);
    transform: rotate(var(--rotation));
    transform-origin: bottom center;
    transition-duration: 600ms;
  }
}

.add-btn {
  border-radius: 50rem;
  padding: 0.1rem 1rem;
  :hover {
    background-color: $gray;
    color: var(--black)
  }
}

.bs_address {
  box-sizing: border-box;
  border-radius: 6px;
  padding: 2px 4px;
  background-color: transparent;
  border: 1px dashed transparent;
  transition: 0.5s;
  text-transform: uppercase;

  &.h_highlight {
    @include background-opacity($yellow);
    border-color: $yellow;
  }
}

.chart-brand-logo {
  opacity: 0.7;
}

.bs_token-social {
  border: 1px solid var(--border);
  border-radius: 4px;
  color: var(--font);

  .dropdown-toggle {
    color: var(--font);
  }

  .dropdown-menu {
    background-color: var(--white);
  }

  .dropdown-item {
    color: var(--font);

    &:hover {
      background-color: var(--bs-bg-secondary);
    }
  }
}

.bs_token-drop {
  min-width: 120px;
  text-align: left;
  border: 1px solid var(--bs-btn-hover-bg);
  border-radius: 6px;
  color: var(--font);
  background-color: var(--bs-btn-bg);
  position: relative;

  .dropdown-toggle {
    color: var(--font);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1.5rem;
  }

  .dropdown-menu {
    min-width: 120px;
    text-align: left;
    background-color: var(--white);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--bs-btn-hover-bg);
    border-radius: 6px;
    margin-top: 3px;
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate(0px, 39px);
    padding-left: 5px;
    padding-right: 5px;
  }

  .dropdown-item {
    color: var(--font);
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 2;
    transition: background-color 0.2s ease;
    border-radius: 6px;

    &:hover {
      background-color: var(--bs-btn-hover-bg);
      border-color: var(--bs-btn-hover-bg);
    }

    .bg-info {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      display: none;

      &[data-img-theme="light"] {
        display: block;
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
    pointer-events: none;
  }

  &:hover {
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-bg);
  }
}

.bs-search-btn {
  min-width: 15px;
  text-align: left;
  border: 1px solid var(--bs-btn-hover-bg);
  border-radius: 6px;
  color: var(--font);
  background-color: var(--bs-btn-bg);

  .dropdown-toggle {
    color: var(--font);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--bs-btn-bg) !important;

    &::after {
      content: none;
    }

    &:hover {
      background-color: var(--bs-btn-hover-bg);
      border-color: var(--bs-btn-hover-bg);
    }
  }

  &:hover {
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-bg);
  }
}

.custom-form {
  display: flex;
  align-items: center;
  width: 100%;

  .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }
}

.hash-tag {
  display: block;
  max-width: 10rem;
  color: var(--font);
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dot {
  background-color: #4cb782;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  display: inline-block;
  flex-shrink: 0;
  margin-right: .5rem !important;
}

.custom-container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1200px) {
  .custom-container:not(.fullWidth) {
    max-width: 1400px;
  }
}

@media (min-width: 1400px) {
  .custom-container:not(.fullWidth) {
    max-width: 1600px;
  }
}

@media (min-width: 1600px) {
  .custom-container:not(.fullWidth) {
    max-width: 1800px;
  }
}

@media (min-width: 1800px) {
  .custom-container:not(.fullWidth) {
    max-width: 2000px;
  }
}

.btn-hov {
  border: 1px solid $gray;
  outline: none;
  padding-top: 6px !important;
  color: var(--font);

  &:hover {
    background-color: var(--bs-btn-hover-bg);
    border-color: $gray;
  }

  &:active,
  &.active,
  &.show,
  &:first-child:active,
  &:not(.btn-check) + .btn:active {
    background-color: var(--bs-btn-hover-bg);
    border-color: $gray;
    outline: none;
  }

  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: none;
  }
}

.btn-check:checked + .btn-hov {
  background-color: var(--bs-btn-hover-bg);
  border-color: $gray;
  outline: none;
}

.code-bg {
  background-color: var(--bs-bg-secondary) !important;
}

.btn-pro {
  color: #081D35;
  background-color: $gray;
  border-color: $gray;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:hover {
    background-color: #dee2e6;
    border-color: $gray;
  }

  &:disabled {
    color: #6c757d;
  }

  &:focus,
  &:focus-visible,
  &:active {
    outline: 0;
  }

  &:active {
    color: #fff !important;
    border-color: #5f6a74 !important;
    background-color: #5f6a74 !important;
  }
}

.custom-scrollbar {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #f8f9fa;
    box-shadow: inset 0 0 6px $gray;
    border-radius: .375rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ced4da;
    transition: background-color 0.3s ease-in-out;
    border-radius: .375rem;

    &::-webkit-scrollbar-thumb:hover {
      background-color: #adb5bd;
    }
  }
}

.custom-menu {
  min-width: 240px;
  max-width: 350px;
  white-space: nowrap;
  overflow-x: auto;
  padding: 0.5rem;
}

.custom-menu .dropdown-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
}

.sub-text {
  font-size: 0.8em;
  margin-left: 0.1em;
}

.bs-link {
  &:hover {
    color: $theme !important;
  }
}

// .bs-danger {
//   background-color: #f06464;
// }

// .bs-sucess {
//   background-color: #C6F6D5;
// }

.breadcrumb-nav {
  margin-left: auto;
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
}

.breadcrumb {
  font-size: 0.875rem;
  margin-bottom: 0;
}

.breadcrumb-item a {
  color: $theme;

  &:hover {
    text-decoration: none;
  }
}

.breadcrumb-item.active {
  color: var(--bs-dashboard-title);
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-md-0 {
  margin-bottom: 0;
}

.bs-hr {
  margin: 1rem 0;
  color: var(--bs-btn-hover-bg);
  border: 0;
  border-top: 1px solid;
  opacity: 1;
}

.title-txt {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 8px;
}

.bg-dark-custom {
  background-color: var(--bs-bg-theme);
}

.bs-font-permn {
  color: var(--bs-text-card-title);
}

ol.list-lg-space {
  list-style-type: decimal;
}

.cm-line {
  font-size: 14px;
}